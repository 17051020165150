<script>
  import { getContext } from "svelte";
  import { Carousel } from "@ObamaFoundation/of-design-system";

  import { buildSrc, srcSetFromWidths, sizesForBreakpoints, SRCSET_WIDTHS } from "$lib/utilities";

  export let data = [];
  export let variant = "portrait";
  export let ariaLabel = undefined;

  let resizeParams = {};
  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");
  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = variant === "portrait" ? { sm: "80vw", md: "40vw" } : { sm: "80vw" };
  const sizes = sizesForBreakpoints(widthsAt);

  /** @type { string } */
  let className = "";
  export { className as class };

  let obj = {};
  let { images, displayCaption } = data;

  let parsedCarouselData = images.map((item) => {
    obj = {
      caption: displayCaption ? item.fields?.caption : "",
      image: buildSrc(item.fields?.imageAsset),
      altText: item.fields?.altText,
      srcset: !ignoreContentfulImgResize
        ? srcSetFromWidths(item.fields?.imageAsset?.fields?.file?.url, SRCSET_WIDTHS, resizeParams)
        : undefined,
      sizes
    };
    return obj;
  });
</script>

<Carousel class={className} {variant} {ariaLabel} data={parsedCarouselData} />
