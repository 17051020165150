<script>
  import Accordion from "$lib/components/Accordion.svelte";
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import CallToActionComponent from "$lib/contentTypes/CallToActionComponent.svelte";
  import EmailSignupForm from "$lib/contentTypes/EmailSignupForm.svelte";
  import FreeRichTextComponent from "$lib/contentTypes/FreeRichTextComponent.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";
  import List from "$lib/components/List.svelte";
  import ListQA from "$lib/components/ListQA.svelte";
  import VideoPlaylist from "$lib/components/VideoPlaylist.svelte";
  import {
    mapAccordionItems,
    mapListQA,
    mapListItems,
    mapVideoPlaylistItems,
    mapTimeline
  } from "$lib/utilities/contentTypes/contentGroup";
  import SplitLayout from "$lib/components/SplitLayout.svelte";
  import Timeline from "$lib/components/Timeline.svelte";
  import EmbedGroup from "./EmbedGroup.svelte";

  const CONTENT_GROUP_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    callToActionComponent: CallToActionComponent,
    emailSignup: EmailSignupForm,
    freeRichTextComponent: FreeRichTextComponent,
    imageComponent: ImageComponent,
    linkComponent: LinkComponent
  };

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;

  const itemContentTypes = data.fields.items.map((item) => {
    return item.sys.contentType.sys.id;
  });

  const LEFT_COLUMN_INDEX = 0;
  const RIGHT_COLUMN_INDEX = 1;

  const getComponent = (typename) => {
    let component = CONTENT_GROUP_COMPONENT_MAP[typename];
    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(`Component missing in Content Group component map: ${typename}`);
    }
    return component;
  };

  const formFactor = data.fields.formFactor;
</script>

{#if formFactor === "Accordion"}
  <Accordion
    titleTag="h3"
    items={mapAccordionItems(data.fields.items)}
    class="list-accordion {className}"
  />
{:else if formFactor === "Q&A"}
  <ListQA items={mapListQA(data.fields.items)} class="list-qa {className}" />
{:else if formFactor == "List: Unordered"}
  {@const listItems = mapListItems(data.fields.items)}
  {#if listItems[0]?.contentType === "embed"}
    <EmbedGroup
      {listItems}
      gridClass="list-embed gap-y-7.5 md:gap-y-15 lg:gap-y-20 xl:gap-y-25 lg:px-15 px-7.5"
    />
  {:else}
    <List variant="unordered" data={listItems} class="list-component {className}" />
  {/if}
{:else if formFactor == "List: Ordered"}
  <List
    variant="ordered"
    data={mapListItems(data.fields.items)}
    class="list-component {className}"
  />
{:else if formFactor === "Wide Image Layout"}
  {@const leftComponent = getComponent(itemContentTypes[LEFT_COLUMN_INDEX])}
  {@const rightComponent = getComponent(itemContentTypes[RIGHT_COLUMN_INDEX])}
  <SplitLayout
    class="wide-image-layout {className}"
    variant={itemContentTypes[LEFT_COLUMN_INDEX] === "freeRichTextComponent" ? "25/75" : "75/25"}
  >
    <svelte:component
      this={leftComponent}
      slot="leftComponent"
      data={data.fields.items[LEFT_COLUMN_INDEX]}
    />
    <svelte:component
      this={rightComponent}
      slot="rightComponent"
      data={data.fields.items[RIGHT_COLUMN_INDEX]}
    />
  </SplitLayout>
{:else if formFactor === "Video Playlist"}
  <VideoPlaylist class={className} videos={mapVideoPlaylistItems(data.fields.items)} />
{:else if formFactor === "Timeline"}
  <Timeline data={mapTimeline(data.fields.items)} class={className} />
{/if}
