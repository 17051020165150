function extractTableHeaderCells(node) {
  return node.content.map((paragraph) => paragraph.content[0].value);
}

function extractTableCells(node) {
  return node.content.reduce((collector, paragraph) => {
    return collector.concat(paragraph.content[0].value, "\n");
  }, "");
}

export function extractTableData(node, headers = [], rowData = []) {
  if (!node) {
    return;
  } else if (node.nodeType === "table-header-cell") {
    headers.push(extractTableHeaderCells(node));
    return;
  } else if (node.nodeType === "table-cell") {
    rowData.push(extractTableCells(node));
    return;
  }

  node.content.forEach((content) => {
    extractTableData(content, headers, rowData);
  });

  return [headers, rowData];
}

export function formatRowData(rowData, numberOfColumns) {
  const formattedRowData = [];

  for (let i = 0; i < rowData.length; i += numberOfColumns) {
    formattedRowData.push(rowData.slice(i, i + numberOfColumns));
  }

  return formattedRowData;
}
