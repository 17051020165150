<script>
  import ImageGrid from "$lib/components/ImageGrid.svelte";
  import Carousel from "$lib/components/Carousel.svelte";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let data;

  const { images, configuration, imageOrientation, name, displayCaption } = data.fields;
  const imageOrientationValue = imageOrientation?.toLowerCase();
</script>

{#if configuration === "grid"}
  <ImageGrid {images} imageOrientation={imageOrientationValue} {displayCaption} class={className} />
{:else if configuration === "carousel"}
  <Carousel class={className} variant={imageOrientationValue} ariaLabel={name} data={data.fields} />
{/if}
